var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var ProfileRepresentationTreeController = (function () {
    function ProfileRepresentationTreeController($q) {
        this.$q = $q;
    }
    ProfileRepresentationTreeController.prototype.$onInit = function () {
        this.isOverriden = this.checkOverridenState(this.root);
    };
    ProfileRepresentationTreeController.prototype.add = function (value) {
        return __awaiter(this, void 0, void 0, function () {
            var work;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.isValueInherited(value)) {
                            this.inherit();
                            return [2];
                        }
                        Object.assign(value, this.root.filter);
                        Object.assign(value, {
                            player: _.pick(this.profile, ['_id', 'firstName', 'lastName']),
                        });
                        work = __spreadArray([value.$save()], this.checkNestedInheritance(value), true);
                        return [4, this.broadcastWork(this.$q.all(work))];
                    case 1:
                        _a.sent();
                        this.root.representations.push(value);
                        this.onChange();
                        return [2];
                }
            });
        });
    };
    ProfileRepresentationTreeController.prototype.remove = function (value) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.broadcastWork(value.$remove())];
                    case 1:
                        _a.sent();
                        this.root.representations = this.root.representations.filter(function (item) { return item !== value; });
                        this.onChange();
                        this.inherit();
                        return [2];
                }
            });
        });
    };
    ProfileRepresentationTreeController.prototype.override = function () {
        this.inherited = null;
    };
    ProfileRepresentationTreeController.prototype.inherit = function () {
        if (this.parent) {
            this.inherited = this.parent.representations;
        }
    };
    ProfileRepresentationTreeController.prototype.onNestedTreeRepresentationChanged = function (value) {
        var _this = this;
        var previousOverridenState = this.isOverriden;
        var currentOverridenState = this.checkOverridenState(this.root);
        if (!previousOverridenState && currentOverridenState) {
            var work_1 = [];
            this.root.leafs.forEach(function (leaf) {
                if (!_this.checkOverridenState(leaf) && !leaf.representations.length) {
                    leaf.representations =
                        angular.copy(_this.root.representations.length ? _this.root.representations : _this.inherited) || [];
                    leaf.representations.forEach(function (item) {
                        delete item._id;
                        Object.assign(item, leaf.filter);
                        work_1.push(item.$save());
                    });
                }
            });
            work_1.push.apply(work_1, this.root.representations.map(function (item) { return item.$remove(); }));
            this.root.representations = [];
            this.broadcastWork(this.$q.all(work_1));
        }
        this.isOverriden = currentOverridenState;
        this.root.expanded = this.root.expanded || currentOverridenState;
        this.onChange();
    };
    ProfileRepresentationTreeController.prototype.onNestedTreeWork = function (promise) {
        this.broadcastWork(promise);
    };
    ProfileRepresentationTreeController.prototype.checkOverridenState = function (root) {
        var _this = this;
        return (root.leafs &&
            root.leafs.length &&
            root.leafs.some(function (leaf) { return _this.checkOverridenState(leaf) || leaf.representations.length > 0; }));
    };
    ProfileRepresentationTreeController.prototype.broadcastWork = function (promise) {
        this.onWork({ $promise: promise });
        return promise;
    };
    ProfileRepresentationTreeController.prototype.isValueInherited = function (value) {
        if (!this.root.representations.length &&
            this.parent &&
            this.parent.representations.length === 1) {
            if (isRepresentationsEqual(this.parent.representations[0], value)) {
                return true;
            }
        }
        return false;
    };
    ProfileRepresentationTreeController.prototype.checkNestedInheritance = function (value) {
        var _this = this;
        if (!this.root.representations.length && this.root.leafs) {
            return this.root.leafs.map(function (item) { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!(item.representations.length === 1 &&
                                isRepresentationsEqual(value, item.representations[0]))) return [3, 2];
                            return [4, item.representations[0].$delete()];
                        case 1:
                            _a.sent();
                            item.representations = [];
                            _a.label = 2;
                        case 2: return [2];
                    }
                });
            }); });
        }
        return [];
    };
    return ProfileRepresentationTreeController;
}());
angular.module('app.profile').component('profileRepresentationTree', {
    templateUrl: 'profile/components/agents/representation-tree.html',
    controller: ProfileRepresentationTreeController,
    bindings: {
        root: '<',
        parent: '<',
        inherited: '<',
        profile: '<',
        onChange: '&',
        onWork: '&',
    },
});
function isRepresentationsEqual(lhs, rhs) {
    var left = _.pick(lhs, ['type', 'agency._id', 'responsibleAgent._id']);
    var right = _.pick(rhs, ['type', 'agency._id', 'responsibleAgent._id']);
    return _.isMatch(left, right);
}
;