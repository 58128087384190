import {NotificationBackend} from '../../general/services/Notifications';

const MAX_PICTURE_SIZE = 5 * 1024 * 1024;

class ProfilePhotoController {
  private $api;
  private $auth;
  private $notifications: NotificationBackend;

  private activeProfile: any;

  protected flowOptions: any;

  constructor(
    private $filter,
    API_END_POINT,
    UserService,
    NotificationCenter,
  ) {
    this.$api = API_END_POINT;
    this.$auth = 'Bearer ' + UserService.getToken();
    this.$notifications = NotificationCenter;
  }

  $onInit() {
    this.flowOptions = {
      target: `${this.$api}/api/profile/${this.activeProfile.type}/${this.activeProfile.profile._id}/picture-upload`,
      simultaneousUploads: 1,
      allowDuplicateUploads: true,
      chunkSize: 1024 * 1024,
      headers: {
        Authorization: this.$auth,
      },
    };
  }

  $onChanges(changes) {
    if (changes.activeProfile && !changes.activeProfile.isFirstChange()) {
      this.flowOptions.target = `${this.$api}/api/profile/${changes.activeProfile.currentValue.type}/${this.activeProfile.currentValue.profile._id}/picture-upload`;
    }
  }

  onUploadSubmit($flow) {
    $flow.files.forEach((file) => {
      if (file.size > MAX_PICTURE_SIZE) {
        this.$notifications.show({
          title: 'My Profile',
          avatar: this.$filter('scImage')(
            this.activeProfile.profile.imageUrl,
            this.activeProfile.type,
          ),
          text: 'The image is too big.',
        });

        $flow.removeFile(file);
      }
    });

    if ($flow.files.length) {
      Object.assign($flow.opts, this.flowOptions);
      $flow.upload();
    }

    // TODO: block for further uploads until current is finished...
    // TODO: start uploading animation state...
  }

  onFileProgress(file) {
    // TODO: update uploading animation state...
  }

  onFileComplete(file, message) {
    Object.assign(this.activeProfile.profile, JSON.parse(message));

    this.$notifications.show({
      title: 'My Profile',
      avatar: this.$filter('scImage')(this.activeProfile.profile.imageUrl, this.activeProfile.type),
      text: 'Picture upload successful!',
    });
  }

  onFileError(file, message) {
    this.$notifications.show({
      title: 'My Profile',
      avatar: this.$filter('scImage')(this.activeProfile.profile.imageUrl, this.activeProfile.type),
      text: 'Unable to upload the picture.',
    });
  }
}

angular.module('app.profile').component('profilePhoto', {
  templateUrl: 'profile/components/photo.html',
  controller: ProfilePhotoController,

  bindings: {
    activeProfile: '<',
    editable: '<',
    editableForm: '<',
  },
});
