(function () {
  'use strict';

  angular
    .module('app.common')
    .directive('scNationalitiesModelTransformer', scNationalitiesModelTransformer);

  function scNationalitiesModelTransformer() {
    return {
      restrict: 'A',
      require: 'ngModel',
      priority: 1000,

      link: function ($scope, $element, $attr, ngModel) {
        ngModel.$formatters.push(($model) => {
          const countries = [
            $model.country,
            $model.secondaryCountry,
            ...($model.otherCountries || []),
          ];

          return countries.filter((item) => !!item);
        });

        $scope.$watchCollection(
          () => ngModel.$viewValue,
          () => {
            ngModel.$modelValue.country = ngModel.$viewValue[0] || undefined;
            ngModel.$modelValue.secondaryCountry = ngModel.$viewValue[1] || undefined;
            ngModel.$modelValue.otherCountries = ngModel.$viewValue.slice(2);

            if (!ngModel.$modelValue.otherCountries.length) {
              ngModel.$modelValue.otherCountries = undefined;
            }
          },
        );
      },
    };
  }
})();
