export interface Representation {
  _id?: string;

  player: {
    _id: string;
    firstName: string;
    lastName: string;
  };

  agency: {
    _id: string;
    name: string;
  };

  responsibleAgent: {
    _id: string;
    firstName: string;
    lastName: string;
  };

  region?: string;
  country?: string;

  type?: 'self-represented' | 'family-represented';
  confirmed?: boolean;
}

export interface RepresentationConflict {
  _id?: string;

  player: {
    _id: string;
    firstName: string;
    lastName: string;
  };

  active: boolean;
  date: string;

  filter: {
    region?: string;
    country?: string;
  };

  initiatorUserId: string;
  initiatorOrganization: {
    _id: string;
    type: string;
  };
}

export interface TreeRoot<R = Representation> {
  filter: any;
  expanded?: boolean;

  representations: R[];
  leafs: TreeRoot<R>[];
}

const EUROPEAN_COUNTRIES = [
  'DEU',
  'AUT',
  'CZE',
  'DNK',
  'FIN',
  'NOR',
  'SWE',
  'ITA',
  'HUN',
  'CHE',
  'EST',
  'ROU',
];

class RepresentationService {
  private $translate;

  private baseUrl;
  private resource;

  constructor(
    private $http,
    $resource,
    $filter,
    SCConfiguration,
  ) {
    this.$translate = $filter('scTranslate');

    this.baseUrl = `${SCConfiguration.getEndpoint()}/api/representation`;
    this.resource = $resource(
      `${this.baseUrl}/:id`,
      {id: '@_id'},
      {
        queryForPlayer: {
          url: `${this.baseUrl}/player/:playerId`,
          isArray: true,
          method: 'GET',
        },
        queryAvailableAgents: {
          url: `${this.baseUrl}/search`,
          isArray: true,
          method: 'GET',
        },
        confirmRepresentations: {
          url: `${this.baseUrl}/confirm`,
          isArray: true,
          method: 'POST',
        },
        applyChangesBulk: {
          url: `${this.baseUrl}/apply-changes`,
          isArray: true,
          method: 'POST',
        },
      },
    );
  }

  queryPlayerRerepresentation(playerId: string): Representation[] {
    return this.resource.queryForPlayer({playerId});
  }

  queryAvailableAgents(query: string): Representation[] {
    return this.resource.queryAvailableAgents({query});
  }

  confirmRepresentations(representationIds: string[]) {
    return this.resource.confirmRepresentations({representationIds}).$promise;
  }

  applyChangesBulk(changes: any[]) {
    return this.resource.applyChangesBulk({changes}).$promise;
  }

  wrap(record: any): any {
    return new this.resource(record);
  }

  createRepresentationTree(representations: Representation[]): TreeRoot<Representation> {
    const SORTED_EUROPEAN_COUNTRIES = _.sortBy(EUROPEAN_COUNTRIES, (country) => {
      return this.$translate(`countries.ISO_3.${country}`);
    });

    const tree: TreeRoot = {
      representations: [],
      filter: {},

      leafs: [
        {
          filter: {region: 'NORTH_AMERICA'},
          representations: [],
          leafs: [],
        },
        {
          filter: {region: 'EUROPE'},
          representations: [],

          leafs: SORTED_EUROPEAN_COUNTRIES.map((country) => {
            return {
              filter: {region: 'EUROPE', country},
              representations: [],
              leafs: [],
            };
          }),
        },
        {
          filter: {region: 'RUSSIA'},
          representations: [],
          leafs: [],
        },
        {
          filter: {region: 'ASIA'},
          representations: [],
          leafs: [],
        },
      ],
    };

    assignRepresentationsToTree(tree, representations.slice());
    assignTreeVisibility(tree);

    return tree;
  }
}

function assignRepresentationsToTree(
  tree: TreeRoot<Representation>,
  representations: Representation[],
) {
  tree.leafs.forEach((nested) => {
    assignRepresentationsToTree(nested, representations);
  });

  tree.representations = representations.filter((item) => _.isMatch(item, tree.filter));
  _.remove(representations, (item) => tree.representations.includes(item));
}

function assignTreeVisibility(tree: TreeRoot) {
  tree.leafs.forEach((nested) => {
    assignTreeVisibility(nested);
  });

  tree.expanded = tree.leafs.some((nested) => nested.expanded || nested.representations.length > 0);
}

angular.module('app.profile').service('RepresentationService', RepresentationService);
