angular.module('app.profile').component('profileBasicsGeneral', {
  templateUrl: 'profile/components/basics/general.html',

  bindings: {
    editable: '<',
    activeProfile: '<',

    hidePictureChanging: '@',
    hideRoles: '@',
  },
});
