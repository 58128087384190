class ProfileService {
  private resource;

  constructor($resource, SCConfiguration) {
    this.resource = $resource(`${SCConfiguration.getEndpoint()}/api/profile/:type`, {
      type: '@type',
    });
  }

  getAvailableProfiles() {
    return this.resource.query();
  }
}

angular.module('app.profile').service('ProfileService', ProfileService);
