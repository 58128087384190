import {Representation, TreeRoot} from '../services/RepresentationService';

class ProfileAgentController {
  private $representationService;

  protected representations: Representation[];
  protected tree: TreeRoot;
  protected activeProfile: any;
  protected promise: Promise<any>;

  constructor(RepresentationService) {
    this.$representationService = RepresentationService;
  }

  $onInit() {
    this.setCurrentWork(this.fetch());
  }

  setCurrentWork(promise: Promise<void>) {
    this.promise = promise;
  }

  isUnconfirmed(representation: Representation) {
    return !representation.confirmed;
  }

  private async fetch() {
    const representations = this.$representationService.queryPlayerRerepresentation(
      this.activeProfile.profile._id,
    );
    await representations.$promise;

    this.representations = representations;
    this.tree = this.$representationService.createRepresentationTree(this.representations);
  }
}

angular.module('app.profile').component('profileAgent', {
  templateUrl: 'profile/components/agent.html',
  controller: ProfileAgentController,

  bindings: {
    activeProfile: '<',
    editable: '<',
    editableForm: '<',
  },
});
