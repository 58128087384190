class ProfileController {
  private $profileService: ProfileService;
  private $userService;
  private $historyService;

  private previousState;
  private availableProfiles;
  private activeProfile;
  private activeProfileBackup;
  private userProfile;
  private userProfileBackup;
  private editable: boolean;

  constructor(
    private $state,
    ProfileService,
    UserService,
    History,
  ) {
    this.$profileService = ProfileService;
    this.$userService = UserService;
    this.$historyService = History;
  }

  $onInit() {
    this.editable = false;
    this.availableProfiles = this.$profileService.getAvailableProfiles();
    this.userProfile = this.$userService.getUser();

    this.availableProfiles.$promise.then((profiles) => {
      this.activeProfile = _.chain(profiles)
        .sortBy((profile) => this.getProfilePriority(profile))
        .value()[0];
    });
  }

  getProfilePriority(profile) {
    switch (profile.type) {
      case 'agent':
        return 1;
      case 'staff':
        return 2;
      case 'player':
        return 3;
      default:
        return Infinity;
    }
  }

  startEditing() {
    this.activeProfileBackup = angular.copy(this.activeProfile);
    this.userProfileBackup = angular.copy(this.userProfile);

    this.editable = true;
  }

  commitChanges() {
    this.editable = false;

    this.activeProfile.$save();
    this.userProfile.$save();
  }

  rollbackChanges() {
    this.editable = false;

    angular.copy(this.activeProfileBackup, this.activeProfile);
    angular.copy(this.userProfileBackup, this.userProfile);
  }

  onActiveProfileChanged() {
    this.$state.go('profile.basic');
  }

  get isBasicPageActive() {
    return this.$state.is('profile.basic');
  }

  exit() {
    this.$historyService.goBack();
  }
}

angular.module('app.profile').component('profile', {
  templateUrl: 'profile/components/profile.html',
  controller: ProfileController,
});
