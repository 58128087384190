class ProfileBasicsRolesController {
  private $userService;

  private activeProfile;
  private accounts;
  private playerStatisticsCache = {};

  constructor(UserService) {
    this.$userService = UserService;
  }

  $onInit() {
    this.$userService.getAvailableAccounts().then((accounts) => {
      this.accounts = accounts;
    });
  }

  isAccountVisible() {
    return (account) => {
      if (!this.activeProfile) {
        return false;
      }

      switch (this.activeProfile.type) {
        case 'agent':
          return account.isPlayer === false;
        case 'staff':
          return account.isPlayer === false;
        case 'player':
          return account.isPlayer === true;
        default:
          return false;
      }
    };
  }

  getAccountKey(account) {
    return `${account.type}-organization:${account._id}`;
  }

  getPlayerStatistics(account) {
    return (this.playerStatisticsCache[account._id] =
      this.playerStatisticsCache[account._id] || this.findPlayerStatistics(account));
  }

  findPlayerStatistics(account) {
    return _.chain(this.activeProfile.profile.stats)
      .filter((item) => item.team._id == account._id)
      .sortBy('season.startYear')
      .last()
      .value();
  }

  transformRoleName(role) {
    return role.display;
  }
}

angular.module('app.profile').component('profileBasicsRoles', {
  templateUrl: 'profile/components/basics/roles.html',
  controller: ProfileBasicsRolesController,

  bindings: {
    editable: '<',
    activeProfile: '<',
  },
});
