angular.module('app.profile').component('profileBasics', {
  templateUrl: 'profile/components/basics.html',

  bindings: {
    activeProfile: '<',
    userProfile: '<',
    editable: '<',
    editableForm: '<',
  },
});
