var ProfileNavigationController = (function () {
    function ProfileNavigationController() {
    }
    Object.defineProperty(ProfileNavigationController.prototype, "isStaffProfile", {
        get: function () {
            return this.activeProfile && this.activeProfile.type === 'staff';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ProfileNavigationController.prototype, "isPlayerProfile", {
        get: function () {
            return this.activeProfile && this.activeProfile.type === 'player';
        },
        enumerable: false,
        configurable: true
    });
    return ProfileNavigationController;
}());
angular.module('app.profile').component('profileNavigation', {
    templateUrl: 'profile/components/navigation.html',
    controller: ProfileNavigationController,
    bindings: {
        activeProfile: '<',
        editable: '<',
    },
});