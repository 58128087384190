import {Representation, TreeRoot} from '../../services/RepresentationService';

class ProfileRepresentationTreeController {
  protected root: TreeRoot;
  protected parent: TreeRoot;

  protected inherited: Representation[];
  protected inheritedBackup: Representation[];

  protected profile: any;

  protected onWork: (params: {$promise: Promise<void>}) => void;
  protected onChange: () => void;

  protected isOverriden: boolean;

  constructor(private $q) {}

  $onInit() {
    this.isOverriden = this.checkOverridenState(this.root);
  }

  async add(value: Representation) {
    if (this.isValueInherited(value)) {
      this.inherit();
      return;
    }

    Object.assign(value, this.root.filter);
    Object.assign(value, {
      player: _.pick(this.profile, ['_id', 'firstName', 'lastName']),
    });

    const work = [(value as any).$save(), ...this.checkNestedInheritance(value)];

    await this.broadcastWork(this.$q.all(work));
    this.root.representations.push(value);
    this.onChange();
  }

  async remove(value: Representation) {
    await this.broadcastWork((value as any).$remove());
    this.root.representations = this.root.representations.filter((item) => item !== value);
    this.onChange();
    this.inherit();
  }

  override() {
    this.inherited = null;
  }
  inherit() {
    if (this.parent) {
      this.inherited = this.parent.representations;
    }
  }

  onNestedTreeRepresentationChanged(value: Representation) {
    const previousOverridenState = this.isOverriden;
    const currentOverridenState = this.checkOverridenState(this.root);

    if (!previousOverridenState && currentOverridenState) {
      const work = [];

      this.root.leafs.forEach((leaf) => {
        if (!this.checkOverridenState(leaf) && !leaf.representations.length) {
          leaf.representations =
            angular.copy(
              this.root.representations.length ? this.root.representations : this.inherited,
            ) || [];
          leaf.representations.forEach((item) => {
            delete item._id;
            Object.assign(item, leaf.filter);

            work.push((item as any).$save());
          });
        }
      });

      work.push(...this.root.representations.map((item) => (item as any).$remove()));

      this.root.representations = [];
      this.broadcastWork(this.$q.all(work));
    }

    this.isOverriden = currentOverridenState;
    this.root.expanded = this.root.expanded || currentOverridenState;

    this.onChange();
  }

  onNestedTreeWork(promise: Promise<void>) {
    this.broadcastWork(promise);
  }

  private checkOverridenState(root: TreeRoot) {
    return (
      root.leafs &&
      root.leafs.length &&
      root.leafs.some((leaf) => this.checkOverridenState(leaf) || leaf.representations.length > 0)
    );
  }

  private broadcastWork(promise: Promise<any>): Promise<any> {
    this.onWork({$promise: promise});

    return promise;
  }

  private isValueInherited(value: Representation) {
    if (
      !this.root.representations.length &&
      this.parent &&
      this.parent.representations.length === 1
    ) {
      if (isRepresentationsEqual(this.parent.representations[0], value)) {
        return true;
      }
    }

    return false;
  }

  private checkNestedInheritance(value: Representation): Promise<void>[] {
    if (!this.root.representations.length && this.root.leafs) {
      return this.root.leafs.map(async (item) => {
        if (
          item.representations.length === 1 &&
          isRepresentationsEqual(value, item.representations[0])
        ) {
          await (item.representations[0] as any).$delete();
          item.representations = [];
        }
      });
    }

    return [];
  }
}

angular.module('app.profile').component('profileRepresentationTree', {
  templateUrl: 'profile/components/agents/representation-tree.html',
  controller: ProfileRepresentationTreeController,

  bindings: {
    root: '<',
    parent: '<',
    inherited: '<',

    profile: '<',
    onChange: '&',
    onWork: '&',
  },
});

function isRepresentationsEqual(lhs: Representation, rhs: Representation) {
  const left = _.pick(lhs, ['type', 'agency._id', 'responsibleAgent._id']);
  const right = _.pick(rhs, ['type', 'agency._id', 'responsibleAgent._id']);

  return _.isMatch(left, right);
}
