var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var ProfileBasicsPersonalController = (function () {
    function ProfileBasicsPersonalController(CountryService) {
        this.countries = CountryService.fetch();
        this.queryCountriesMemoizedWorker = _.memoize(this.queryCountriesWorker.bind(this), function (params) {
            return params.query + '|' + params.exclude.map(function (country) { return country.id; }).join(',');
        });
    }
    ProfileBasicsPersonalController.prototype.$onInit = function () {
        this.currentDate = moment().toDate();
    };
    ProfileBasicsPersonalController.prototype.$onChanges = function (params) {
        if (params.activeProfile && params.activeProfile.currentValue) {
            _.merge(params.activeProfile.currentValue.profile, { passports: [] });
        }
    };
    ProfileBasicsPersonalController.prototype.queryCountries = function (query, exclude) {
        var _this = this;
        if (this.countries.length) {
            return this.queryCountriesMemoizedWorker({ query: query, exclude: exclude });
        }
        return this.countries.$promise.then(function () {
            return _this.queryCountriesMemoizedWorker({ query: query, exclude: exclude });
        });
    };
    ProfileBasicsPersonalController.prototype.queryCountriesWorker = function (_a) {
        var query = _a.query, exclude = _a.exclude;
        exclude = exclude.map(function (country) { return country.id; });
        query = query.toLocaleLowerCase();
        return this.countries.filter(function (country) {
            return (!exclude.includes(country.id) && country.name.toLocaleLowerCase().indexOf(query) !== -1);
        });
    };
    Object.defineProperty(ProfileBasicsPersonalController.prototype, "profileNations", {
        get: function () {
            if (!this.activeProfile) {
                return null;
            }
            var countries = __spreadArray([
                this.activeProfile.profile.country,
                this.activeProfile.profile.secondaryCountry
            ], (this.activeProfile.profile.otherCountries || []), true);
            return countries.filter(function (item) { return !!item; });
        },
        enumerable: false,
        configurable: true
    });
    return ProfileBasicsPersonalController;
}());
angular.module('app.profile').component('profileBasicsPersonal', {
    templateUrl: 'profile/components/basics/personal.html',
    controller: ProfileBasicsPersonalController,
    bindings: {
        editable: '<',
        activeProfile: '<',
    },
});