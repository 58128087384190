var ProfileController = (function () {
    function ProfileController($state, ProfileService, UserService, History) {
        this.$state = $state;
        this.$profileService = ProfileService;
        this.$userService = UserService;
        this.$historyService = History;
    }
    ProfileController.prototype.$onInit = function () {
        var _this = this;
        this.editable = false;
        this.availableProfiles = this.$profileService.getAvailableProfiles();
        this.userProfile = this.$userService.getUser();
        this.availableProfiles.$promise.then(function (profiles) {
            _this.activeProfile = _.chain(profiles)
                .sortBy(function (profile) { return _this.getProfilePriority(profile); })
                .value()[0];
        });
    };
    ProfileController.prototype.getProfilePriority = function (profile) {
        switch (profile.type) {
            case 'agent':
                return 1;
            case 'staff':
                return 2;
            case 'player':
                return 3;
            default:
                return Infinity;
        }
    };
    ProfileController.prototype.startEditing = function () {
        this.activeProfileBackup = angular.copy(this.activeProfile);
        this.userProfileBackup = angular.copy(this.userProfile);
        this.editable = true;
    };
    ProfileController.prototype.commitChanges = function () {
        this.editable = false;
        this.activeProfile.$save();
        this.userProfile.$save();
    };
    ProfileController.prototype.rollbackChanges = function () {
        this.editable = false;
        angular.copy(this.activeProfileBackup, this.activeProfile);
        angular.copy(this.userProfileBackup, this.userProfile);
    };
    ProfileController.prototype.onActiveProfileChanged = function () {
        this.$state.go('profile.basic');
    };
    Object.defineProperty(ProfileController.prototype, "isBasicPageActive", {
        get: function () {
            return this.$state.is('profile.basic');
        },
        enumerable: false,
        configurable: true
    });
    ProfileController.prototype.exit = function () {
        this.$historyService.goBack();
    };
    return ProfileController;
}());
angular.module('app.profile').component('profile', {
    templateUrl: 'profile/components/profile.html',
    controller: ProfileController,
});