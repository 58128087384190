class ProfileBasicsContactsController {
  private userProfile;

  $onInit() {
    if (!this.userProfile.phones) {
      this.userProfile.phones = [];
    }
  }

  addPhone() {
    this.userProfile.phones.push({});
  }

  removePhone(phone) {
    const index = this.userProfile.phones.indexOf(phone);
    this.userProfile.phones.splice(index, 1);
  }
}

angular.module('app.profile').component('profileBasicsContacts', {
  templateUrl: 'profile/components/basics/contacts.html',
  controller: ProfileBasicsContactsController,

  bindings: {
    editable: '<',
    editableForm: '<',
    userProfile: '<',
  },
});
