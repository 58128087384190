var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
(function () {
    'use strict';
    angular
        .module('app.common')
        .directive('scNationalitiesModelTransformer', scNationalitiesModelTransformer);
    function scNationalitiesModelTransformer() {
        return {
            restrict: 'A',
            require: 'ngModel',
            priority: 1000,
            link: function ($scope, $element, $attr, ngModel) {
                ngModel.$formatters.push(function ($model) {
                    var countries = __spreadArray([
                        $model.country,
                        $model.secondaryCountry
                    ], ($model.otherCountries || []), true);
                    return countries.filter(function (item) { return !!item; });
                });
                $scope.$watchCollection(function () { return ngModel.$viewValue; }, function () {
                    ngModel.$modelValue.country = ngModel.$viewValue[0] || undefined;
                    ngModel.$modelValue.secondaryCountry = ngModel.$viewValue[1] || undefined;
                    ngModel.$modelValue.otherCountries = ngModel.$viewValue.slice(2);
                    if (!ngModel.$modelValue.otherCountries.length) {
                        ngModel.$modelValue.otherCountries = undefined;
                    }
                });
            },
        };
    }
})();