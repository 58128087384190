class ProfileNavigationController {
  private activeProfile;

  get isStaffProfile() {
    return this.activeProfile && this.activeProfile.type === 'staff';
  }

  get isPlayerProfile() {
    return this.activeProfile && this.activeProfile.type === 'player';
  }
}

angular.module('app.profile').component('profileNavigation', {
  templateUrl: 'profile/components/navigation.html',
  controller: ProfileNavigationController,
  bindings: {
    activeProfile: '<',
    editable: '<',
  },
});
