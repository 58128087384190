var ProfileBasicsLanguagesController = (function () {
    function ProfileBasicsLanguagesController(LanguageService) {
        this.$languageService = LanguageService;
    }
    ProfileBasicsLanguagesController.prototype.$onChanges = function (params) {
        if (params.userProfile && params.userProfile.currentValue) {
            _.merge(params.userProfile.currentValue, { languages: [] });
        }
    };
    Object.defineProperty(ProfileBasicsLanguagesController.prototype, "availableLanguages", {
        get: function () {
            return this.$languageService.languages.filter(function (language) { return true; });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ProfileBasicsLanguagesController.prototype, "availableLevels", {
        get: function () {
            return this.$languageService.levels;
        },
        enumerable: false,
        configurable: true
    });
    ProfileBasicsLanguagesController.prototype.addLanguage = function () {
        this.userProfile.languages.push({});
    };
    ProfileBasicsLanguagesController.prototype.removeLanguage = function (language) {
        var index = this.userProfile.languages.indexOf(language);
        this.userProfile.languages.splice(index, 1);
    };
    return ProfileBasicsLanguagesController;
}());
angular.module('app.profile').component('profileBasicsLanguages', {
    templateUrl: 'profile/components/basics/languages.html',
    controller: ProfileBasicsLanguagesController,
    bindings: {
        editable: '<',
        userProfile: '<',
    },
});
;